<template>
<div class="examinList" v-loading="pageLoading">
    <div class="left">
      <b v-if="examinList[currentIndex]">{{examinList[currentIndex].type_name}}</b>
      <div class="examin" v-if="examinList.length && examinList[currentIndex]">
        <div class="title">
            {{currentIndex+1}}.{{examinList[currentIndex].title}}
        </div>
        <!-- 单选 判断-->
        <div class="answer" v-if="examinList[currentIndex].type_id == 1 || examinList[currentIndex].type_id == 3">
          <div
            :class="{
              err: isReview && list[currentIndex].result==item.flag && !list[currentIndex].status,
              checked:
                !isReview && item.flag==currentAnswer || isReview && item.flag==examinList[currentIndex].result,
            }"
            class="answerItem"
            v-for="(item, index) in answerList"
            @click="chooseAnswer(item)"
            :key="index"
          >
            <img  v-if="isReview && currentInfo.type_id == 1" :src="
              list[currentIndex].status && item.flag != currentAnswer || !list[currentIndex].status && item.flag != currentAnswer  && item.flag != currentInfo.result ?
                check :
                list[currentIndex].status && item.flag == currentInfo.result || !list[currentIndex].status && item.flag == currentInfo.result  ?  
                checked : checkedRed
              "/>
              <img  v-if="isReview && currentInfo.type_id == 3" :src="
              currentInfo.is_correct == 2 || currentInfo.is_correct == 1 && item.value != currentAnswer || currentInfo.is_correct == 0 && item.value != currentAnswer  && item.value != currentInfo.result ?
                check :
                currentInfo.is_correct == 1 && item.value == currentInfo.result || currentInfo.is_correct == 0 && item.value == currentInfo.result  ?  
                checked : checkedRed
              "/>
            <img  v-if="!isReview" :src=" currentAnswer == item.flag ? checked : check"/>
            {{ item.flag }}.{{ item.text }}
          </div>
        </div>
        <!-- 多选-->
        <div class="answer" v-if="examinList[currentIndex].type_id == 2">
          <div
            :class="{ 
              checked: !isReview && currentAnswer.indexOf(item.flag) != -1 || isReview && list[currentIndex].status && currentAnswer.indexOf(item.flag) != -1,
              err:isReview && !list[currentIndex].status && currentAnswer.indexOf(item.flag) != -1
            }"
            class="answerItem"
            v-for="(item, index) in answerList"
            @click="chooseAnswer2(item)"
            :key="index"
          >
          <img v-if="!isReview" :src="currentAnswer.indexOf(item.flag) >-1 ? checked2 : check2"/>
          <img v-if="isReview" :src="
            currentAnswer.indexOf(item.flag) == -1 ? check2 :  
            list[currentIndex].status &&  list[currentIndex].result.indexOf(item.flag) > -1 ? checked2 : checkedRed2"
          />
            {{ item.flag }}.{{ item.text }}
          </div>
        </div>
        <!-- 填空 -->
        <div class="answer" v-if="examinList[currentIndex].type_id == 4">
          <!-- || (isReview && list[currentIndex].result.indexOf(item.flag) !=-1)  -->
          <div
            :class="{err:isReview && item.text != JSON.parse(currentInfo.result)[index]}"
            class="answerItem"
            v-for="(item, index) in answerList"
            :key="index"
          >{{ index+1 }}、
          <input type="text" @input="changeAnswer(index,item)" class="inputText" v-model.trim="item.text"/>
          <!-- <img v-if="!isReview" :src="currentAnswer.indexOf(item.flag) >-1 ? checked2 : check2"/>
            {{ item.flag }}.{{ item.text }} -->
          </div>
        </div>
        <!-- 解析 -->
        <div class="explain" v-if="isReview">
           <b>解析</b>
           <div class="rightAnswer">
             正确答案：<span>{{ currentInfo.type_id==3 ? currentInfo.result==1?'对' : '错' : currentInfo.result }}</span>
           </div>
           <p>{{ currentInfo.explain }}</p>
        </div>
      </div>
      <div class="btn">
          <p :class="{active:currentIndex!=0}" @click="changeTitle('pre')">上一题</p>
          <p :class="{active: currentIndex < examinList.length-1}" @click="changeTitle('next')">下一题</p>
        </div>
    </div>
    <div class="right">
       <div class="time" v-if="type==1">
          <p class="title"><img :src="time"/>剩余时间</p>
          <p class="timeCount">{{timeCount}}</p>
       </div>
       <div class="card">
          <p class="title"><img :src="card"/>答题卡</p>
          <ul>
            <!-- is_correct 0是错误 1正确 2未提交过 -->
            <li @click="changeAnswerIndex(index)" v-for="(item,index) in examinList" 
              :class="{
                blue:!isReview && (resultList[index] || examinList[index].user_result),
                green: isReview && list[index].status == 1,
                red: isReview && list[index].status == 0,
                }" :key="index">
              {{index+1}}
            </li>
          </ul>
       </div>
       <div class="btn" v-if="!isReview" @click="submit">交卷</div>
    </div>
</div>
</template>

<script>
import check from "./images/check.png"
import checked from "./images/checked.png"
import checkedRed from "./images/checkedRed.png"
import check2 from "./images/check2.png"
import checked2 from "./images/checked2.png"
import checkedRed2 from "./images/checkedRed2.png"
import time from "./images/examinTime.png"
import card from "./images/examinCard.png"
export default {
  name: "lesson",
  data() {
    return {
      check,
      checked,
      checkedRed,
      check2,
      checked2,
      checkedRed2,
      time,
      card,

      timeLimit:60,
      timeCount:'',
      timer:null,
      type:0, //0模拟 1正式
      pageLoading: true,
      isReview:false,
    currentIndex:-1,
    currentAnswer:'',
    report_id:'',
    list:[],
    examinList:[{
      title:'题目',
      type_id:1,
      options:[{
        flag:'A',
        text:'选项A'
      }]
    },{
      title:'题目2',
      type_id:2,
      options:[{
        flag:'A',
        text:'选项A'
      }]
    }],

    resultList: [],
    answerList: [],
    answerObject:[],
    err:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%88%A0%E9%99%A4.png',
    right:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%AF%B9%E5%8F%B7%20(1).png',
    };
  },
  async mounted() {
    this.id = this.$route.query.id
    this.isReview = this.$route.query.isExamin ? false : true
    this.getData();
  },
  destroyed() {
    
  },
  computed: {
   currentInfo(){
        return this.currentIndex > -1 ? this.examinList[this.currentIndex] : {}
    },

  },
  watch:{
    currentIndex(){
      if(this.isReview){
        this.currentAnswer = this.list[this.currentIndex].result
      }else{
        this.currentAnswer = this.resultList[this.currentIndex] &&  this.resultList[this.currentIndex].result ?  this.resultList[this.currentIndex].result : ''
      }
      console.log(this.currentAnswer)
      if(this.examinList[this.currentIndex].type_id==4){ //填空
        this.answerList =  this.currentAnswer ? JSON.parse(this.currentAnswer).map(item=>{
          let v = {'text':item}
          return v
        }) : new Array(this.currentInfo.number).fill({text:''})
        this.answerObject = JSON.parse(JSON.stringify(this.answerList))
      }else if(this.examinList[this.currentIndex].type_id==3){
        this.answerList = [{
          flag: 'A',
          value: 1,
          text: '对'
        },{
          flag: 'B',
          text: '错',
          value: 2,
        }]
      }else{
        this.answerList = this.examinList[this.currentIndex].options 
        ? JSON.parse(this.examinList[this.currentIndex].options)
        : [];
      }
    },
  },
  methods: {
    // 切答题卡
   async changeAnswerIndex(index){
      this.currentIndex = index
    },
    // 交卷
  submit() {
    let finishList = this.resultList.filter(item => !item || !item.result)
    if(finishList.length){
      this.$toast('题目未全部完成')
      return
    }
    this.$confirm('确定交卷？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.pageLoading = true;
          let time = (new Date().getTime() - this.timeStart.getTime())/1000
          this.$api({
            method: "post",
            url: this.$store.state.plat_id==119 || this.$store.state.plat_id==171 ? "/api/goods/test/answer" : "/api/flat/test/answer",
            data: {
              test_id: this.id,
              duration:time,
              answer:JSON.stringify(this.resultList)
            },
          }).then(res=>{
            this.pageLoading = false;
            if (res.code == 0) {
              this.$toast('提交成功')
              setTimeout(()=>{
                this.$router.replace({
                  path:'./examinResult',
                  query:{
                    id:res.data.id,
                    isPass:res.data.is_pass,
                    examinId:this.id
                  }
                })
              },1500)
            } else {
              this.$toast(res.msg);
            }
            
          })
          
          
        }).catch(() => {
                  
        });
  },
  // 切换题目
  changeTitle(type){
    if(type=='pre' && this.currentIndex == 0 || type == 'next' && this.currentIndex > this.examinList.length -2){
      return
    }
    if(this.isReview){
      this.currentIndex = type=='pre' ? this.currentIndex-1 : this.currentIndex+1
      
      return
    }
    if(!this.currentAnswer){
      this.$toast('请选择答案')
      return
    }

    
    this.currentIndex = type=='pre' ? this.currentIndex-1 : this.currentIndex+1
    
    this.currentAnswer = this.resultList[this.currentIndex] ? this.resultList[this.currentIndex].result : ''
  },
  // 切换答案
  chooseAnswer(item){
    if(this.isReview){
      return
    }
    this.currentAnswer = item.flag
    let myAnswer = {
      result : this.currentAnswer,
      question_id:this.examinList[this.currentIndex].question_id,
      score:this.examinList[this.currentIndex].score
    }
    this.$set(this.resultList,this.currentIndex,myAnswer)
    // this.resultList[this.currentIndex]=myAnswer
     
  },
  // 多选选择答案
  chooseAnswer2(item) {
      if(this.isReview) return
      let answerArr = this.currentAnswer ? this.currentAnswer.split(",") : [];
      // console.log(answerArr);
      let index = answerArr.findIndex((v) => v == item.flag);
      if (index == -1) {
        answerArr.push(item.flag);
      } else {
        answerArr.splice(index, 1);
      }
      this.currentAnswer = answerArr.sort().join(",");
      // console.log(this.currentAnswer);
      let myAnswer = {
        result: this.currentAnswer,
        question_id: this.examinList[this.currentIndex].question_id,
        score: this.examinList[this.currentIndex].score,
      };
      this.$set(this.resultList,this.currentIndex,myAnswer)
      // this.resultList[this.currentIndex]=myAnswer
    },
    // 填空
    changeAnswer(index,item){
      this.answerObject[index] = item
      this.answerList = JSON.parse(JSON.stringify(this.answerObject))
      if(this.answerList.findIndex(item => item.text)!=-1){
        this.currentAnswer = JSON.stringify(this.answerList.map(item=>item.text))
      }else{
        this.currentAnswer = ''
      }
      let myAnswer = {
        result: this.currentAnswer,
        question_id: this.examinList[this.currentIndex].question_id,
        score: this.examinList[this.currentIndex].score,
      };
      this.resultList[this.currentIndex]=myAnswer
    },
    async getData() {
      this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/flat/test/index",
        data: {
          test_id: this.id,
        },
      });
      if(!this.isExamin){
        let res2 = await this.$api({
          method: "get",
          url: "/api/flat/test/detail",
          data: {
            test_report_id: this.$route.query.resultId,
          },
        });
        if (res2.code == 0) {
          this.list = res2.data.list;
        } else {
          this.$toast(res2.msg);
        }
      }
      this.pageLoading = false;
      if (res.code == 0) {
        this.examinList = res.data.list;
        this.resultList = new Array(this.examinList.length).fill(null)
        this.currentIndex = this.$route.query.currentIndex * 1 || 0
        this.timeStart = new Date()
      } else {
        this.$toast(res.msg);
      }
    },
    
    jumpDetail(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar{
  width: 8px;
  height: 7.6px;
  border-radius: 5px;
  background-color: #F4F4F4;
}
::-webkit-scrollbar-thumb{
  /*// border-radius:5px;*/
  background-color:#DEDEE4;
  border-radius:5px;
  margin: 5px 0;
}
.examinList {
  // min-height: calc(100vh - 230px);
  display: flex;
  justify-content: center;
  width:1200px;
  background: #fff;
  margin: 20px auto;
  display: flex;
  .left{
    flex: 1;
    border-radius: 8px;
    height: 456px;
    background: #f6f9fc;
    
    padding: 20px;
    display: flex;
    flex-direction: column;
    b{
      font-size: 22px;
      color: #333333;
      display: block;
      margin-bottom: 20px;
      margin-left: 10px;
    }
    .btn{
      display: flex;
      justify-content: flex-end;
      p{
        width: 122px;
        height: 34px;
        background: #fff;
        border-radius: 4px;
        text-align: center;
        line-height: 34px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        margin-left: 20px;
        display: inline-block;
        color: #2e66d0;
        border: 1px solid #2e66d0;
      }
      p.active{
        background: #2e66d0;
        color: #fff;
      }
    }
  }
  .right{
    margin-left: 20px;
    width: 300px;
    display: flex;
    height: 456px;
    flex-direction: column;
    
    .time,.card{
      background: #f6f9fc;
      width: 100%;
      padding: 10px 0 10px 20px;
      border-radius: 8px;
    }
    .time{
      padding-right: 20px;
    }
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 18px;
        margin: 10px 0;
        img{
          margin-right: 10px;
        }
      }
    .time{
      margin-bottom: 20px;
      
      .timeCount{
        background: #fff;
        border-radius: 10px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        color: #f74456;
      }
    }
    .card{
      flex: 1;
      overflow-y: auto;
      height:100%;
      ul{
        overflow: hidden;
        li{
          float: left;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          border: 1px solid #cbced0;
          line-height: 34px;
          text-align: center;
          color: #333333;
          cursor: pointer;
          margin-right: 20px;
          margin-bottom: 12px;
        }
        li:nth-of-type(5n+5){
          margin-right: 0;
        }
        li.blue{
          color: #fff;
          background: #2e66d0;
          border-color: #2e66d0;
        }
        li.red{
          color: #fff;
          background: #e85555;
          border-color: #e85555;
        }
        li.green{
          color: #fff;
          background: #3cd0b6;
          border-color: #3cd0b6;
        }
      }
    }
    .btn{
      margin-top: 20px;
      height: 53px;
      border-radius: 4px;
      line-height: 53px;
      text-align: center;
      background: #2e66d0;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .examin{
    background: #fff;
    width: 100%;
    border-radius: 8px;
    flex: 1;
    padding: 30px 20px;
    overflow-y: auto;
    margin-bottom: 20px;
    .title{
      font-size: 20px;
      color: #333;
    }
    .answer{
      padding: 20px 16px;
      .answerItem{
        width: 100%;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img{
          width: 18px;
          margin-right: 10px;
        }
        p{
          flex:1;
          font-size: 16px;
          color: #333;
        }
        span{
            color: #666;
            font-size: 12px;
            width: 20px;
            height: 20px;
            background: #FFFFFF;
            border: 1px solid #C4C4C4;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
            margin-right: 7px;
            display: block;
          }
      }
      .checked{
        color: #2e66d0;
      }
      .err{
        color: #FF5F6E;
        span{
          background: #FF5F6E;
          border-color: #FF5F6E;
          color:#fff
        }
      }
    }
    
  }
  
}
</style>

<style lang="scss">
</style>
